<template>
  <div>
    <dashboard-navbar :routes="routes"></dashboard-navbar>
    <div class="content" @click="$sidebar.displaySidebar(false)">
      <div class="container-fluid mt-2">
        <section class="card-student">
          <div class="wrapper-heading">
            <h2 class="heading-card-student">Detail Trainer Profile</h2>
          </div>
          <section class="card-student-body">
            <loading-panel v-if="loading.get_detail" />
            <validation-observer
              v-else-if="!loading.get_detail && !error.get_detail"
              v-slot="{ handleSubmit }"
              ref="formValidator"
            >
              <form role="form" @submit.prevent="handleSubmit(hanldePopUpSubmit)">
                <div class="wrapper-photo">
                  <div class="photo-student">
                    <img class="photo" :src="preview" alt="photo Student" />
                  </div>
                  <!-- <base-button size="sm" outline type="primary" class="button-delete">
                    <label class="label-upload">
                      <div>Upload Image</div>
                      <input
                        id="inputFile"
                        class="file-upload"
                        type="file"
                        accept="image/*"
                        @change="previewImage"
                        ref="imageFile"
                      />
                    </label>
                  </base-button>-->
                  <label class="label-upload">
                    <div
                      class="btn base-button btn-outline-primary btn-sm button-delete"
                    >Upload Image</div>
                    <input
                      id="inputFile"
                      class="file-upload"
                      type="file"
                      accept="image/*"
                      @change="previewImage"
                      ref="imageFile"
                    />
                  </label>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      label="Name"
                      class="mb-3"
                      name="Name"
                      placeholder="-"
                      :rules="{ required: true }"
                      v-model="account.name"
                    ></base-input>
                  </div>
                  <div class="col-md-6">
                    <base-input
                      label="NPA IDI"
                      class="mb-3"
                      name="NPA IDI"
                      placeholder="-"
                      v-model="account.npaidi"
                      :rules="{ required: true, }"
                    ></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      label="Gender"
                      class="mb-3"
                      name="Gender"
                      :rules="{ required: true }"
                    >
                      <select v-model="account.gender.value" class="form-control">
                        <option
                          v-for="gender in account.gender.list"
                          :key="gender.name"
                          :value="gender.name"
                        >{{ gender.name }}</option>
                      </select>
                    </base-input>
                  </div>
                  <div class="col-md-6">
                    <base-input
                      label="Telephone Number"
                      class="mb-3"
                      name="Telephone"
                      placeholder="-"
                      type="number"
                      :rules="{ required: true }"
                      v-model="account.nomor_tlp"
                    ></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      label="Profession"
                      class="mb-3"
                      name="Profession"
                      placeholder="-"
                      :rules="{ required: true }"
                      v-model="account.profesi"
                    ></base-input>
                  </div>
                  <div class="col-md-6">
                    <base-input
                      label="Email"
                      class="mb-3"
                      name="Email"
                      placeholder="-"
                      type="email"
                      :rules="{ required: true, email: true }"
                      v-model="account.email"
                    ></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      label="Institute"
                      class="mb-3"
                      name="Institute"
                      placeholder="-"
                      :rules="{ required: true }"
                      v-model="account.institute"
                    ></base-input>
                  </div>
                  <div class="col-md-6">
                    <base-input label="Bank" class="mb-3" name="Bank" :rules="{ required: true }">
                      <select class="form-control" v-model="account.bank.value">
                        <option
                          v-for="bank in account.bank.list"
                          :key="bank.name"
                          :value="bank.code"
                        >{{ bank.name }}</option>
                      </select>
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      label="Account Number"
                      class="mb-3"
                      name="Account Number"
                      placeholder="-"
                      type="number"
                      :rules="{ required: true }"
                      v-model="account.account_number"
                    ></base-input>
                  </div>
                </div>
                <div class="text-right">
                  <base-button outline type="primary" class="my-4" @click="handleCancel">Cancel</base-button>
                  <base-button type="edit-vr" native-type="submit" class="my-4">Save Changes</base-button>
                </div>
              </form>
            </validation-observer>
          </section>
        </section>
      </div>
    </div>
    <modal :show.sync="showEditModal" modal-classes="modal-secondary">
      <template slot="header">
        <h6 class="modal-title">Edit Detail Trainer Profile</h6>
      </template>
      <template slot="close-button"></template>
      <span class="body-modal">
        Are you sure to
        <span class="change">save these change</span> ?
      </span>
      <template slot="footer">
        <div class="text-right">
          <base-button outline type="primary" class="my-4" @click="showEditModal = false">Back</base-button>
          <base-button type="edit-vr" class="my-4" @click="onSubmit">Save Changes</base-button>
        </div>
      </template>
    </modal>
    <modal :show.sync="loading.submit" modal-classes="modal-secondary">
      <loading-panel />
    </modal>
    <modal :show.sync="loading.change_image" modal-classes="modal-secondary">
      <loading-panel />
    </modal>
  </div>
</template>
<script>
import DashboardNavbar from '@/Layout/DashboardNavbar.vue';
import { getDetailTrainer, updateDataTrainer, getListBank } from '@/services/trainer'
import LoadingPanel from '@/components/LoadingPanel.vue';
export default {
  components: {
    DashboardNavbar,
    LoadingPanel
  },
  data() {
    return {
      routes: [
        {
          name: "ListTrainers",
          alias: "LIST TRAINER"
        },
        {
          name: "editTrainer",
          alias: "EDIT"
        },
      ],
      account: {
        image: "",
        name: '',
        npaidi: '',
        gender:
        {
          value: '',
          list:
            [
              { name: 'Laki-laki', },
              { name: 'Perempuan', },
            ]
        },
        nomor_tlp: '',
        profesi: '',
        email: '',
        institute: '',
        bank: {
          value: '',
          list: []
        },
        account_number: ''
      },
      preview: "/img/default_picture.png",
      image: null,
      showEditModal: false,
      loading: {
        get_detail: false,
        submit: false,
        change_image: false
      },
      error: {
        get_detail: false,
        submit: false
      }
    }
  },
  methods: {
    hanldePopUpSubmit() {
      this.showEditModal = true
    },
    async onSubmit() {
      try {
        const form = new FormData();
        form.append('email', this.account.email);
        form.append('name', this.account.name);
        form.append('npaidi', this.account.npaidi);
        form.append('gender', this.account.gender.value);
        form.append('nomor_tlp', this.account.nomor_tlp);
        form.append('profesi', this.account.profesi);
        form.append('institute', this.account.institute);
        form.append('bank', this.account.bank.value);
        form.append('account_number', this.account.account_number);
        form.append('id', +this.$route.params.id);
        form.append('photo', this.image);
        this.showEditModal = false
        this.loading.submit = true
        await updateDataTrainer(form)
        this.loading.submit = false
        this.$notify({
          type: "success",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "Data Saved Successfully"
        });
        setTimeout(() => {
          this.$router.push({ name: "ListTrainers" })
        }, 1000);
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
    handleCancel() {
      this.$router.push({ name: "ListTrainers" })
    },
    async getDetail() {
      try {
        this.loading.get_detail = true
        this.error.get_detail = false
        const { data } = await getDetailTrainer(this.$route.params.id)
        const { data: ListBank } = await getListBank()
        this.loading.get_detail = false
        this.account.name = data?.name
        this.account.npaidi = data?.npaidi
        this.account.gender.value = data?.gender
        this.account.nomor_tlp = data?.nomor_tlp
        this.account.profesi = data?.profesi
        this.account.email = data?.email
        this.account.institute = data?.institute
        this.account.bank.value = data?.bank
        this.account.bank.list = ListBank
        this.account.account_number = data?.account_number
        this.preview = data?.photo
      } catch (error) {
        this.loading.get_detail = false
        this.error.get_detail = true
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
    async previewImage() {
      const types = ['image/png', 'image/jpg', 'image/jpeg']
      if (types.includes(this.$refs.imageFile.files[0].type)) {
        if ((this.$refs.imageFile.files[0].size / 1024).toFixed(2) < 3000) {
          try {
            this.image = this.$refs.imageFile.files[0];
            this.loading.change_image = true
            const form = new FormData();
            form.append('email', this.account.email);
            form.append('name', this.account.name);
            form.append('npaidi', this.account.npaidi);
            form.append('gender', this.account.gender.value);
            form.append('nomor_tlp', this.account.nomor_tlp);
            form.append('profesi', this.account.profesi);
            form.append('institute', this.account.institute);
            form.append('bank', this.account.bank.value);
            form.append('account_number', this.account.account_number);
            form.append('id', +this.$route.params.id);
            form.append('photo', this.image);
            await updateDataTrainer(form)
            this.loading.change_image = false
            this.preview = URL.createObjectURL(this.image);
            this.$refs.imageFile.value = null
          } catch (error) {
            if (error.code === 401) {
              this.$cookies.remove('CERT');
              this.$store.dispatch('auth/removeAuth');
              this.$router.replace({ name: "Login" })
              this.$notify({
                type: "danger",
                verticalAlign: "top",
                horizontalAlign: "center",
                closeOnClick: true,
                message: error.msg
              });
            } else {
              this.$notify({
                type: "danger",
                verticalAlign: "top",
                horizontalAlign: "center",
                closeOnClick: true,
                message: error
              });
            }
          }
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: "File size exceeds the limit"
          });
        }
      } else {
        this.$notify({
          type: "danger",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "File must be png/jpg/jpeg"
        });
      }
    },
  },
  mounted() {
    this.getDetail()
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/sass/argon.scss";
.card-student-body {
  background-color: #fff;
  position: relative;
  padding: 20px;

  .wrapper-photo {
    display: flex;
    height: 120px;
    align-items: center;
    justify-content: center;

    .photo-student {
      position: absolute;
      transform: translate(0, -50%) scale(1);
      height: 170px;
      width: 170px;
      border-radius: 100px;
      background: rgb(175, 174, 174);
      overflow: hidden;

      .photo {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .button-delete {
      margin-top: 45px;
    }
  }
}
.label-upload {
  margin: 0;
  cursor: pointer;
  .file-upload {
    display: none;
  }
}
.body-modal {
  font-size: 20px;
  font-weight: 600;
  .change {
    color: $orange-course;
  }
}
</style>